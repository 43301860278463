var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content app-content",class:[
    {
      'show-overlay': _vm.$store.state.app.shallShowOverlay,
      'fit-screen': _vm.fitScreen,
      'only-nav-bar': _vm.hasOnlyNavBar
    },
    _vm.$route.meta.contentClass ]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"content-wrapper",class:_vm.contentWidth === 'boxed' ? 'container p-0' : null,style:(_vm.hasBOBreadcrumb
        ? 'margin-top: 3rem'
        : _vm.isMobile || _vm.hasOnlyNavBar
          ? 'margin-top: 0;'
          : ((_vm.hasBackofficeMenu|| _vm.hasMyselfMenu) && _vm.contentLayoutType !== 'vertical')
            ? 'margin-top: 4.5rem;'
            : _vm.hasNotMenu ||
              _vm.$route.name === 'calendar' ||
              _vm.contentLayoutType === 'vertical'
              ? 'margin-top: -35px'
              : _vm.activatedApps.length <= 0
                ? 'margin-top: -30px;'
                : _vm.isHome
                  ? 'margin-top: 4.5rem;'
                  : 'margin-top: 3.5rem;')},[_vm._t("breadcrumb",[_c('app-breadcrumb')]),_c('div',{staticClass:"content-body",class:_vm.hasOnlyNavBar?'no-content-body-margin':''},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"},on:{"before-enter":_vm.handleBeforeEnter,"after-enter":_vm.handleAfterEnter}},[_vm._t("default")],2)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }