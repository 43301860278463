<template>
  <div
    class="content app-content"
    :class="[
      {
        'show-overlay': $store.state.app.shallShowOverlay,
        'fit-screen': fitScreen,
        'only-nav-bar': hasOnlyNavBar
      },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <!-- <div :class="hasOnlyNavBar?'':(hasNavbarShadow ? 'header-navbar-shadow' : '')" /> -->
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
      :style="
        hasBOBreadcrumb
          ? 'margin-top: 3rem'
          : isMobile || hasOnlyNavBar
            ? 'margin-top: 0;'
            : ((hasBackofficeMenu|| hasMyselfMenu) && contentLayoutType !== 'vertical')
              ? 'margin-top: 4.5rem;'
              : hasNotMenu ||
                $route.name === 'calendar' ||
                contentLayoutType === 'vertical'
                ? 'margin-top: -35px'
                : activatedApps.length <= 0
                  ? 'margin-top: -30px;'
                  : isHome
                    ? 'margin-top: 4.5rem;'
                    : 'margin-top: 3.5rem;'"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body" :class="hasOnlyNavBar?'no-content-body-margin':''">
        <transition
          :name="routerTransition"
          mode="out-in"
          @before-enter="handleBeforeEnter"
          @after-enter="handleAfterEnter"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {
  menuItems,
  backofficeDetailsRoutes,
} from '@/backoffice/constants/backofficeMenu';
import { myselfMenuItems } from '@/views/myself/components/myMenu';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';

export default {
  components: {
    AppBreadcrumb,
  },
  mixins: [GridStatusMixin],

  props: {
    fitScreen: Boolean,
  },
  provide() {
    return {
      hasEntered: () => this.hasEntered,
    };
  },
  data() {
    return { hasEntered: false, isSmall: '' };
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
  computed: {
    isHome() {
      return this.$route.name === 'Resumen';
    },
    isVerticalLayout() {
      return this.contentLayoutType === 'vertical';
    },
    isMobile() {
      return window.innerWidth < 1000;
    },
    contentLayoutType() {
      if (this.isMobile) {
        return 'vertical';
      }
      if (this.$route.path.includes('/backoffice') || this.$route.path.includes('/myself')) {
        return 'vertical';
      }
      if (this.$store.getters.currentCollective.menu?.layout) {
        return this.$store.getters.currentCollective.menu.layout;
      }
      return this.$store.state.appConfig.layout.type;
    },
    hasNavbarShadow() {
      return (
        this.isVerticalLayout
        || this.activatedApps.length > 0
        || this.hasBackofficeMenu
        || this.hasBOBreadcrumb
        || this.hasMyselfMenu
      );
    },
    hasNotMenu() {
      return (
        this.$route.name === 'welcome'
        || menuItems.includes(this.$route.name)
        || this.$route.name === 'globalMemberSettings'
        || this.$route.name === 'myRoles'
        || this.$route.name === 'myself-subcommunities'
        || this.$route.name === 'myConnections'
        || this.$route.name === 'calendar'
        || this.$route.name === 'myDashboard'
        || this.$route.name === 'email'
        || this.$route.name === 'myself-subscription'
        || this.$route.name === 'onboarding-form'
        || this.$route.name === 'onboarding-plans'
        || this.$route.name === 'onboarding-checkout'
        || this.$route.name === 'onboarding-success'
        || this.$route.name === 'myself-referrals'
        || this.$route.name === 'orders'
        || this.$route.name === 'my-payments'
        || this.$route.name === 'passport'
        || this.$route.name === 'myself-notifications'
        || this.$route.name === 'developers-center'
        || this.$route.name === 'my-role-details'
        || this.$route.name === 'myself-likes'
      );
    },
    hasOnlyNavBar() {
      return (
        this.$route.name === 'forms-detail'
        || this.$route.name === 'forms-success'
        || this.$route.name === 'onboarding-form'
      );
    },
    hasBackofficeMenu() {
      return menuItems.includes(this.$route.name);
    },
    hasMyselfMenu() {
      return (
        myselfMenuItems.includes(this.$route.name)
        || this.$route.name === 'my-role-details'
      );
    },
    hasBOBreadcrumb() {
      return (
        backofficeDetailsRoutes.includes(this.$route.name)
        || this.$route.name === 'my-role-details'
      );
    },
    activatedApps() {
      const { apps } = this.$store.getters.apps;
      if (!apps) {
        return {};
      }
      return Object.values(apps).filter((app) => app?.isInMenu);
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleBeforeEnter() {
      this.hasEntered = false;
    },
    handleAfterEnter() {
      this.hasEntered = true;
    },
    handleResize() {
      this.isSmall = window.innerWidth <= 1200;
    },
  },
};
</script>

<style lang="scss" scoped>
.fit-screen {
  &.app-content {
    height: 100vh;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .content-body {
    flex-grow: 1;
  }
}

</style>
